import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerItems = [
    // { item: "FAQs", to: "faqs" },
    // { item: "Nutzungsbedingungen", to: "nutzungsbedingungen" },
    // { item: "Privacy Policy", to: "privacypolicy" },
  ];
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <p className={styles.text}> Name is here
          2023. All Rights Reserved.</p>
        <div className={styles.footerItems}>
          {footerItems.map((el, i) => (
            <Link to={el.to} key={i} className={styles.link}>
              {el.item}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
